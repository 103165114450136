
.smallpad {
    margin-left: -5px;
    margin-right: -5px;

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.clickimage {
    cursor: pointer;
    border: 2px solid #999;
    margin-bottom: 10px;
    &:hover {
        border: 2px solid #000;
    }
    &.active {
        border: 2px solid #F00;
    }
}

.calendartable {
    .foglalt {
        background-color: #ffddff;
        color: #111;
    }
    td {
        cursor: pointer;
    }
}

textarea.autosize {
    resize: none;
}

hr.st {
    margin-top: 5px;
}
hr.sb {
    margin-bottom: 5px;
}
hr.nt {
    margin-top: 0px;
}
hr.nb {
    margin-bottom: 0px;
}

.m-b-2 {
    margin-bottom: 2px !important;
}
@for $i from 0 through 5 {
    .m-t-#{$i*5} {
        margin-top: #{$i*5}px !important;
    }
    .m-b-#{$i*5} {
        margin-bottom: #{$i*5}px !important;
    }
    .m-r-#{$i*5} {
        margin-right: #{$i*5}px !important;
    }
    .m-l-#{$i*5} {
        margin-left: #{$i*5}px !important;
    }
}
